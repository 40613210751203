<template>
  <div class="leet-carbon-page">
    <div class="page-header">
      <img src="https://cdn.lnxall.com/banner3.png" alt="" srcset="" />
      <div class="title-box">
        <div class="title-content">
          <div class="top-title animate__animated animate__backInLeft">
            零碳能源大脑
          </div>
          <div
            class="info-des animate__animated animate__backInLeft animate__delay-1s"
          >
            充分发挥简捷物联的核心技术优势 <br />有效打通各类<span
              >双碳基础设施</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- one -->
    <div
      class="page-full-body-content"
      style="height: 548px; background: #ffffff"
    >
      <div class="full-page-content">
        <div class="title-box">
          通过绿色能源、绿色建筑、绿色交通、绿色产业等多全碳场景的接入，<br />
          成功构建双碳生态系统，帮助客户轻松实现形成“5个1”——
        </div>
        <div class="data-box">
          <div class="data-item">
            <img
              src="https://cdn.lnxall.com/01.png"
              style="width: 82px; height: 69px"
              alt=""
              srcset=""
            />
            <div class="intro">双碳基础设施一张网</div>
          </div>
          <div class="data-item">
            <img
              src="https://cdn.lnxall.com/02.png"
              style="width: 88px; height: 69px"
              alt=""
              srcset=""
            />
            <div class="intro">双碳态势感知一张图</div>
          </div>
          <div class="data-item">
            <img
              src="https://cdn.lnxall.com/03.png"
              style="width: 89px; height: 69px"
              alt=""
              srcset=""
            />
            <div class="intro">双碳资源监控一盘棋</div>
          </div>
        </div>
        <div class="data-box" style="margin-top: 20px; padding: 0 200px">
          <div class="data-item">
            <img
              src="https://cdn.lnxall.com/04.png"
              style="width: 82px; height: 69px"
              alt=""
              srcset=""
            />
            <div class="intro">双碳风险管控一条线</div>
          </div>
          <div class="data-item">
            <img
              src="https://cdn.lnxall.com/05.png"
              style="width: 88px; height: 69px"
              alt=""
              srcset=""
            />
            <div class="intro">双碳产业服务一站通</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="height: 1820px; background: #f8fbff; position: relative"
    >
      <titleBar title="架构设计" style="margin-top: 80px"></titleBar>
      <div class="sub-title-box">零碳能源大脑</div>
      <div
        class="page-top-content"
        style="background: url('https://cdn.lnxall.com/top.png') 100% 100% no-repeat"
      ></div>
      <div
        class="page-bottom-content"
        style="background: url('https://cdn.lnxall.com/bottom.png') 100% 100% no-repeat"
      ></div>
    </div>
    <div class="page-full-body-content" style="position: relative">
      <div
        class="mask-model"
        style="background: url('https://cdn.lnxall.com/qx.png')  no-repeat;background-size: 100% 100%;"
      ></div>
      <div class="page-full-one">
        <titleBar title="三大核心模块" style="margin-top: 80px"></titleBar>
        <div class="page-body-content">
          <div class="leet-content-intro" style="margin-top: 110px">
            <div
              class="image-content"
              style="
                background: url('https://cdn.lnxall.com/lee01.png') 100% 100% no-repeat;
              "
            ></div>
            <div class="info-content" style="margin-top: 84px">
              <div class="title-info" style="width: 340px">
                物联网边缘计算模块
                <div class="left-top-icon">
                  <img src="https://cdn.lnxall.com/leeleft.png" alt="" srcset="" />
                </div>
                <div class="right-top-icon">
                  <img src="https://cdn.lnxall.com/leeright.png" alt="" srcset="" />
                </div>
              </div>
              <div class="intro">节能控碳策略及碳排放预警，有效控碳管理</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-full-one" style="height: 540px; background: #f8fbff">
        <div class="page-body-content">
          <div class="leet-content-intro" style="margin-top: 110px">
            <div class="info-content" style="margin-top: 84px; padding: 0 20px">
              <div class="title-info" style="width: 230px">
                能源管理模块
                <div class="left-top-icon">
                  <img src="https://cdn.lnxall.com/leeleft.png" alt="" srcset="" />
                </div>
                <div class="right-top-icon">
                  <img src="https://cdn.lnxall.com/leeright.png" alt="" srcset="" />
                </div>
              </div>
              <div class="intro">可视化地图实时监测碳排放以及碳资产情况</div>
            </div>
            <div
              class="image-content"
              style="
                background: url('https://cdn.lnxall.com/lee02.png') 100% 100% no-repeat;
                width: 385px;
                height: 338px;
              "
            ></div>
          </div>
        </div>
      </div>
      <div class="page-full-one" style="height: 588px; background: #ffffff">
        <div class="page-body-content">
          <div class="leet-content-intro" style="margin-top: 110px">
            <div
              class="image-content"
              style="
                background: url('https://cdn.lnxall.com/lee03.png') 100% 100% no-repeat;
                width: 505px;
                height: 280px;
              "
            ></div>
            <div class="info-content" style="margin-top: 84px">
              <div class="title-info" style="width: 340px">
                双碳管理模块
                <div class="left-top-icon">
                  <img src="https://cdn.lnxall.com/leeleft.png" alt="" srcset="" />
                </div>
                <div class="right-top-icon">
                  <img src="https://cdn.lnxall.com/leeright.png" alt="" srcset="" />
                </div>
              </div>
              <div class="intro">实时掌握碳中和目标及路径，控碳有的放矢</div>
            </div>
          </div>
          <div class="card-content" style="margin-top: 130px">
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon01.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">信息可视化</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon02.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">多维报表</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon03.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">耗能监测</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon04.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">用能动态</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon05.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">隔墙用能交易</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon06.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">区域节能策略</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon07.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">碳排放监测</div>
            </div>
            <div
              class="card-list"
              style="
                background: url('https://cdn.lnxall.com/lee05.png') -20px -28px no-repeat;
              "
            >
              <img
                src="https://cdn.lnxall.com/icon08.png"
                style="width: 55px; height: 52px"
                alt=""
                srcset=""
              />
              <div class="sub-intro">碳汇交易</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="page-full-one"
        style="height: 300px; background: #f8fbff"
      ></div>
    </div>
    <div class="page-full-body-content" style="background-color: #ffffff">
      <div class="selection-part-two">
        <titleBar title="功能特点" style="margin-top: 76px"></titleBar>
        <div
          class="section-content"
          @mouseenter="cardactive = false"
          @mouseleave="cardactive = true"
        >
          <div
            class="item-card-list"
            :class="[cardactive ? 'actvie-card' : '']"
          >
            <div class="card-info">
              可视化地图实时监测碳排放以及碳资产情况
            </div>
          </div>
          <div class="item-card-list">
            <div class="card-info">节能控碳策略及碳排放预警,有效控碳管理</div>
          </div>
          <div class="item-card-list">
            <div class="card-info">分户分项碳排放统计,精细化碳管理及交易</div>
          </div>
          <div class="item-card-list">
            <div class="card-info">实时掌握碳中和目标及路径,控碳有的放矢</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="
        margin-top: 0;
        height: 560px;
        background: url('https://cdn.lnxall.com/img17.png') no-repeat; background-size: 100% 100%;
      "
    >
      <div class="leet-full-content">
        <titleBar title="用户价值" style="margin-top: 77px"></titleBar>
        <div class="selection-part-two">
          <div class="plane-content">
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/icon09.png" alt="" srcset="" />
            </div>
            <div class="des">设备节能<br/>精细化管理</div>
          </div>
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/icon10.png" alt="" srcset="" />
            </div>
            <div class="des">企业能源<br/>信息化集中管理</div>
          </div>
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/icon11.png" alt="" srcset="" />
            </div>
            <div class="des">整体能源<br/>系统化智慧管理</div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "@/components/titleBar";
export default {
  name: "carbon-page",
  components: {
    titleBar,
  },
  data() {
    return {
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="scss">
.leet-carbon-page {
  .page-header {
    height: 860px;
    background-color: #000106;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .title-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 267px;
      box-sizing: border-box;
      .title-content {
        width: 1200px;
        max-width: 1200px;
        margin: 0 auto;
        .top-title {
          height: 70px;
          color: #ffffff;
          font-size: 50px;
          letter-spacing: 2.77777767px;
          font-family: PingFangSC-Medium;
          line-height: 70px;
        }
        .info-des {
          margin-top: 63px;
          font-size: 50px;
          font-family: PingFangSC-Light;
          color: #ffffff;
          line-height: 80px;
          letter-spacing: 4.2px;
          span {
            font-size: 50px;
            color: #ffffff;
            font-family: PingFangSC-Medium;
          }
        }
      }
    }
  }
  .page-full-body-content {
    border: 1px solid transparent;
    box-sizing: border-box;
    .title-content {
      text-align: center;
      height: 42px;
      color: #222222;
      font-size: 30px;
      letter-spacing: 2px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 42px;
      margin-top: 170px;
    }
    .plane-content {
      display: flex;
      justify-content: space-around;
      .plane-item {
        width: 300px;
        height: 425px;
        .imagebox {
          width: 100%;
          text-align: center;
          margin-top: 116px;
          img {
            width: 85px;
            height: 84px;
          }
        }
        .des {
          margin-top: 55px;
          text-align: center;
          height: 40px;
          color: #222222;
          font-size: 28px;
          letter-spacing: 1px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 40px;
        }
      }
    }
    .selection-part-two {
      margin: 0 auto;
      width: 1202px;
      height: 576px;
      max-width: 1202px;
      box-sizing: border-box;
      border: 1px solid transparent;
      .section-title {
        width: 100%;
        text-align: center;
        color: #222222;
        font-size: 30px;
        letter-spacing: 2px;
        font-family: PingFangSC-Medium;
        line-height: 42px;
        box-sizing: border-box;
        margin-top: 76px;
      }
      .section-content {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        .item-list {
          height: 202px;
          border-radius: 8px;
          background: linear-gradient(360deg, #ffffff 0%, #ffffff 100%);
          border: 1px solid #f5f5f5;
          box-shadow: 0px 8px 20px 0px rgb(213 213 227 / 20%);
          background-size: 620px 240px;
          width: 582px;
          // margin: 0 38px 38px 0;
          margin-bottom: 38px;
          position: relative;
          .iconbox {
            position: absolute;
            top: 39px;
            left: 55px;
            width: 48px;
            height: 48px;
            img {
              width: 48px;
              height: 48px;
            }
          }
          .titlebox {
            position: absolute;
            top: 39px;
            left: 145px;
            width: 200px;
            height: 30px;
            color: #222222;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            text-align: left;
          }
          .info {
            position: absolute;
            top: 78px;
            left: 145px;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 20px;
            line-height: 36px;
            text-align: left;
            margin-top: 9px;
            width: 400px;
          }
        }
        // three
        .item-card-list {
          width: 300px;
          height: 300px;
          box-sizing: border-box;
          background-image: url("https://cdn.lnxall.com/assets/basis04.gif");
          background-repeat: no-repeat;
          background-size: 240px 360px;
          position: relative;
          box-shadow: 4px 0px 84px 0px rgba(183, 188, 205, 0.17);
          .card-title {
            position: absolute;
            top: 82px;
            left: 30px;
            height: 30px;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            text-align: left;
          }
          .card-info {
            position: absolute;
            width: 178px;
            top: 90px;
            left: 30px;
            font-size: 20px;
            line-height: 36px;
            text-align: left;
            margin-top: 27px;
          }
        }
        .actvie-card {
          background-image: url("https://cdn.lnxall.com/assets/basis05.gif") !important;
          color: #fff !important;
        }
        .item-card-list:hover {
          background-image: url("https://cdn.lnxall.com/assets/basis05.gif") !important;
          color: #fff !important;
        }
      }
    }
    .mask-model {
      position: absolute;
      width: 1920px;
      height: 2083px;
      left: 0px;
      top: 0px;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    .full-page-content {
      width: 1202px;
      max-width: 1202px;
      min-width: 1202px;
      margin: 0 auto;
      .title-box {
        margin-top: 80px;
        overflow-wrap: break-word;
        color: #222222;
        font-size: 22px;
        letter-spacing: 1px;
        line-height: 48px;
        text-align: center;
      }
      .data-box {
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
        .data-item {
          position: relative;
          width: 380px;
          height: 100px;
          border-radius: 8px;
          box-shadow: 0px 16px 24px 0px rgb(183 188 205 / 20%);
          overflow: hidden;
          background-color: #fff;
          background-size: 428px 148px;
          position: relative;
          img {
            position: absolute;
            top: 0px;
            left: 0px;
          }
          .intro {
            position: absolute;
            left: 92px;
            top: 35px;
            height: 30px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 22px;
            font-family: PingFangSC-Medium;
            white-space: nowrap;
            line-height: 30px;
            text-align: right;
          }
        }
      }
    }
    .sub-title-box {
      width: 192px;
      height: 42px;
      color: #222222;
      font-size: 30px;
      letter-spacing: 2px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 42px;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 220px;
    }
    .page-top-content {
      width: 1862px;
      height: 1861px;
      margin: 0 auto;
    }
    .page-bottom-content {
      width: 1380px;
      height: 622px;
      margin: -900px auto;
    }
    .page-full-one {
      border: 1px solid transparent;
      height: 650px;
      background-color: #fff;
      box-sizing: border-box;
    }
    .page-body-content {
      width: 1202px;
      max-width: 1202px;
      min-width: 1202px;
      margin: 0 auto;
      .card-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .card-list {
          height: 120px;
          border-radius: 8px;
          box-shadow: 0px -8px 20px 0px rgb(213 223 227 / 20%);
          background-size: 310px 160px;
          width: 270px;
          margin-bottom: 30px;
          position: relative;
          img {
            top: 32px;
            left: 38px;
            position: absolute;
          }
          .sub-intro {
            height: 30px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 20px;
            white-space: nowrap;
            line-height: 30px;
            text-align: left;
            margin-top: 13px;
            position: absolute;
            top: 32px;
            left: 122px;
          }
        }
      }
    }
  }
  .leet-content-intro {
    display: flex;
    justify-content: space-between;
    .image-content {
      width: 588px;
      height: 321px;
    }
    .info-content {
      display: inline-block;
      .title-info {
        position: relative;
        height: 40px;
        display: inline-block;
        overflow-wrap: break-word;
        color: #222222;
        font-size: 36px;
        letter-spacing: 1.7999999523162842px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 40px;
        text-align: left;
        padding: 0 20px;
        display: flex;
        .left-top-icon {
          width: 34px;
          height: 35px;
          position: absolute;
          top: -22px;
          left: -16px;
          z-index: 2;
          img {
            width: 34px;
            height: 35px;
          }
        }
        .right-top-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          bottom: 14px;
          right: -6px;
          z-index: 2;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .intro {
        padding: 0 20px;
        height: 40px;
        display: inline-block;
        overflow-wrap: break-word;
        color: #555555;
        font-size: 24px;
        letter-spacing: 1.2000000476837158px;
        white-space: nowrap;
        line-height: 40px;
        text-align: left;
        margin-top: 20px;
      }
    }
  }
}
</style>
