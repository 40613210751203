<template>
  <div class="page-body">
    <div class="page-body-content">
      <div class="selection-part-one">
        <titleBar title="站控系统" :other="true"></titleBar>
        <div class="content">
          SMC站控系统是简捷物联专为储能（微网）电站定制的新一代能量管理系统（EMS）。它既具备传统EMS的高时效、高稳定、高可靠等本地一体化系统的优势，又兼具云计算、平台化管理系统的大数据分析处理、设备远程监控、以及灵活、轻便、简单、快捷的部署维护能力。
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="background-color: #ffffff; height: 610px; position: relative"
    >
      <div class="selection-part-two">
        <div class="section-title">产品优势</div>
        <div
          class="section-content"
          @mouseenter="cardactive = false"
          @mouseleave="cardactive = true"
        >
          <div
            class="item-card-list card1"
            :class="[cardactive ? 'actvie-card' : '']"
          >
            <div class="card-title">系统监控</div>
          </div>
          <div class="item-card-list card2">
            <div class="card-title">能量调度</div>
          </div>
          <div class="item-card-list card3">
            <div class="card-title">设备管理</div>
          </div>
          <div class="item-card-list card4">
            <div class="card-title">运行维护</div>
          </div>
          <div class="item-card-list card5">
            <div class="card-title">数据分析</div>
          </div>
        </div>
      </div>
      <div
        class="site-arrow"
        style="background: url('https://cdn.lnxall.com/zkarrow.png')"
      ></div>
    </div>
    <!-- 特点 -->
    <div
      class="page-full-body-content"
      style="background: #f4f8f8; height: 741px"
    >
      <div class="selection-part-two">
        <div class="section-title">特点优势</div>
        <div class="leet-content-intro" style="margin-top: 125px">
          <div
            class="image-content"
            style="background: url('https://cdn.lnxall.com/img1.png')"
          ></div>
          <div class="info-content" style="margin-top: 84px">
            <div class="title-info">
              云边协同设计
              <div class="left-top-icon">
                <img src="https://cdn.lnxall.com/lefttop.png" alt="" srcset="" />
              </div>
              <div class="right-top-icon">
                <img src="https://cdn.lnxall.com/rightbottom.png" alt="" srcset="" />
              </div>
            </div>
            <div class="intro">便捷丰富的管理手段</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="background: #ffffff; height: 681px"
    >
      <div class="selection-part-two">
        <div class="leet-content-intro" style="margin-top: 197px">
          <div class="info-content" style="margin-top: 84px">
            <div class="title-info">
              设备统一接入
              <div class="left-top-icon">
                <img src="https://cdn.lnxall.com/lefttop.png" alt="" srcset="" />
              </div>
              <div class="right-top-icon">
                <img src="https://cdn.lnxall.com/rightbottom.png" alt="" srcset="" />
              </div>
            </div>
            <div class="intro">数据全量监控，提升系统可维护性</div>
          </div>
          <div
            class="image-content"
            style="background: url('https://cdn.lnxall.com/img2.png')"
          ></div>
        </div>
      </div>
      <div
        class="page-full-image-bg"
        style="
          height: 360px;
          background: url('https://cdn.lnxall.com/img3.png') no-repeat;
          background-size: 100% 100%;
        "
      ></div>
    </div>
    <div
      class="page-full-body-content"
      style="background: #f4f8f8; height: 681px"
    >
      <div class="selection-part-two">
        <div class="leet-content-intro" style="margin-top: 197px">
          <div
            class="image-content"
            style="background: url('https://cdn.lnxall.com/img4.png')"
          ></div>
          <div class="info-content" style="margin-top: 84px">
            <div class="title-info">
              智能策略管理
              <div class="left-top-icon">
                <img src="https://cdn.lnxall.com/lefttop.png" alt="" srcset="" />
              </div>
              <div class="right-top-icon">
                <img src="https://cdn.lnxall.com/rightbottom.png" alt="" srcset="" />
              </div>
            </div>
            <div class="intro">保障系统安全及收益最大化</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="background: #ffffff; height: 681px"
    >
      <div class="selection-part-two">
        <div class="leet-content-intro" style="margin-top: 197px">
          <div class="info-content" style="margin-top: 84px">
            <div class="title-info">
              全周期多维度数据分析
              <div class="left-top-icon">
                <img src="https://cdn.lnxall.com/lefttop.png" alt="" srcset="" />
              </div>
              <div class="right-top-icon">
                <img src="https://cdn.lnxall.com/rightbottom.png" alt="" srcset="" />
              </div>
            </div>
            <div class="intro">系统智能巡检，提升系统安全性</div>
          </div>
          <div
            class="image-content"
            style="background: url('https://cdn.lnxall.com/img5.png')"
          ></div>
        </div>
      </div>
      <div
        class="page-full-image-bg"
        style="
          height: 432px;
          bottom: 46px;
          background: url('https://cdn.lnxall.com/img6.png') no-repeat;
          background-size: 100% 100%;
        "
      ></div>
    </div>
    <div
      class="page-full-body-content"
      style="background: #f4f8f8; height: 276px"
    >
      <div class="selection-part-three">
        <div class="selection-three-content" style="margin-top: -26px">
          <div class="item-list">
            <div class="imgbox">
              <img src="https://cdn.lnxall.com/img7.png" alt="" />
            </div>
            <div class="content">开放式可编程设计，客户自由扩展定制</div>
          </div>
          <div class="item-list">
            <div class="imgbox">
              <img src="https://cdn.lnxall.com/img8.png" alt="" />
            </div>
            <div class="content">一体式标准化结构设计，稳定可靠</div>
          </div>
        </div>
        <div class="selection-three-content">
          <div class="item-list">
            <div class="imgbox">
              <img src="https://cdn.lnxall.com/img9.png" alt="" />
            </div>
            <div class="content">安全合规</div>
          </div>
          <div class="item-list">
            <div class="imgbox">
              <img src="https://cdn.lnxall.com/img10.png" alt="" />
            </div>
            <div class="content">
              多种产品形态灵活支撑不同应用场景，<br />简便易用
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "@/components/titleBar";
export default {
  name: "site-page",
  components: {
    titleBar,
  },
  data() {
    return {
      active: 1,
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="scss">
.page-body {
  box-sizing: border-box;
  background-color: #ffffff;
  .page-body-content {
    margin: 0 auto;
    width: 1200px;
    max-width: 1200px;
    box-sizing: border-box;
    .selection-part-one {
      height: 180px;
      padding: 76px 0 60px 0;
      .content {
        overflow-wrap: break-word;
        color: #222222;
        font-size: 22px;
        letter-spacing: 1px;
        line-height: 48px;
        text-align: left;
        margin-top: 40px;
      }
    }
  }
  .page-full-body-content {
    width: 100%;
    height: 726px;
    background-color: rgba(72, 136, 209, 0.06);
    box-sizing: border-box;
    position: relative;
    .page-full-image-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      width: 100%;
    }
    // arrow
    .site-arrow {
      position: absolute;
      right: 50%;
      left: 50%;
      bottom: -75px;
      width: 71px;
      height: 87px;
      transform: translate(-50%, -50%);
      text-align: center;
      box-sizing: border-box;
      z-index: 2;
    }
    .selection-part-two {
      margin: 0 auto;
      width: 1202px;
      height: 726px;
      max-width: 1202px;
      min-width: 1202px;
      box-sizing: border-box;
      border: 1px solid transparent;
      .leet-content-intro {
        display: flex;
        justify-content: space-between;
        padding: 0 158px;
        .image-content {
          width: 254px;
          height: 247px;
        }
        .info-content {
          display: inline-block;
          .title-info {
            position: relative;
            height: 40px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 36px;
            letter-spacing: 1.7999999523162842px;
            font-family: PingFangSC-Medium;
            white-space: nowrap;
            line-height: 40px;
            text-align: left;
            padding: 0 20px;
            display: flex;
            .left-top-icon {
              width: 34px;
              height: 35px;
              position: absolute;
              top: -22px;
              left: -16px;
              z-index: 2;
              img {
                width: 34px;
                height: 35px;
              }
            }
            .right-top-icon {
              width: 20px;
              height: 20px;
              position: absolute;
              bottom: 14px;
              right: -6px;
              z-index: 2;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
          .intro {
            padding: 0 20px;
            height: 40px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 24px;
            letter-spacing: 1.2000000476837158px;
            white-space: nowrap;
            line-height: 40px;
            text-align: left;
            margin-top: 20px;
          }
        }
      }
      .section-title {
        width: 100%;
        text-align: center;
        color: #222222;
        font-size: 30px;
        letter-spacing: 2px;
        font-family: PingFangSC-Medium;
        line-height: 42px;
        box-sizing: border-box;
        margin-top: 100px;
      }
      .section-content {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        .item-list {
          height: 202px;
          border-radius: 8px;
          background: linear-gradient(360deg, #ffffff 0%, #ffffff 100%);
          border: 1px solid #f5f5f5;
          box-shadow: 0px 8px 20px 0px rgb(213 213 227 / 20%);
          background-size: 620px 240px;
          width: 582px;
          // margin: 0 38px 38px 0;
          margin-bottom: 38px;
          position: relative;
          .iconbox {
            position: absolute;
            top: 39px;
            left: 55px;
            width: 48px;
            height: 48px;
            img {
              width: 48px;
              height: 48px;
            }
          }
          .titlebox {
            position: absolute;
            top: 39px;
            left: 145px;
            width: 200px;
            height: 30px;
            color: #222222;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            text-align: left;
          }
          .info {
            position: absolute;
            top: 78px;
            left: 145px;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 20px;
            line-height: 36px;
            text-align: left;
            margin-top: 9px;
            width: 400px;
          }
        }
        // three
        .card1 {
          background: url("https://cdn.lnxall.com/assets/xtjk0.png") -79px -83px no-repeat;
        }
        .card1:hover {
          background: url("https://cdn.lnxall.com/assets/xtjk1.png") -7px -24px no-repeat;
          color: #fff;
        }
        .card2 {
          background: url("https://cdn.lnxall.com/assets/nldd0.png") -79px -83px no-repeat;
        }
        .card2:hover {
          background: url("https://cdn.lnxall.com/assets/nldd1.png") -7px -24px no-repeat;
          color: #fff;
        }
        .card3 {
          background: url("https://cdn.lnxall.com/assets/sbgl0.png") -79px -83px no-repeat;
        }
        .card3:hover {
          background: url("https://cdn.lnxall.com/assets/sbgl1.png") -7px -24px no-repeat;
          color: #fff;
        }
        .card4 {
          background: url("https://cdn.lnxall.com/assets/yxwh0.png") -79px -83px no-repeat;
        }
        .card4:hover {
          background: url("https://cdn.lnxall.com/assets/yxwh1.png") -7px -24px no-repeat;
          color: #fff;
        }
        .card5 {
          background: url("https://cdn.lnxall.com/assets/sjfx0.png") -79px -83px no-repeat;
        }
        .card5:hover {
          background: url("https://cdn.lnxall.com/assets/sjfx1.png") -7px -24px no-repeat;
          color: #fff;
        }
        .item-card-list {
          width: 240px;
          height: 320px;
          box-sizing: border-box;
          position: relative;
          box-shadow: 4px 0px 84px 0px rgba(183, 188, 205, 0.17);
          background-size: 406px 486px;
          .card-title {
            position: absolute;
            top: 186px;
            left: 0;
            right: 0;
            height: 30px;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            text-align: center;
          }
          .card-info {
            position: absolute;
            width: 178px;
            top: 110px;
            left: 30px;
            font-size: 20px;
            line-height: 36px;
            text-align: left;
            margin-top: 27px;
          }
        }
        .actvie-card {
          background: url("https://cdn.lnxall.com/assets/xtjk1.png") -7px -24px no-repeat !important;
          color: #fff !important;
        }
      }
    }
    .selection-part-three {
      margin: 0 auto;
      width: 1202px;
      max-width: 1202px;
      min-width: 1202px;
      box-sizing: border-box;
      border: 1px solid transparent;
      .selection-three-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 140px;
        .item-list {
          height: 120px;
          border-radius: 8px;
          box-shadow: 0px -8px 20px 0px rgb(213 223 227 / 20%);
          width: 580px;
          background-color: #ffffff;
          display: flex;
          justify-content: space-between;
          .imgbox {
            width: 166px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 55px;
              height: 52px;
            }
          }
          .content {
            width: 412px;
            height: 120px;
            display: flex;
            align-items: center;
            color: #555555;
            font-size: 20px;
            white-space: nowrap;
            line-height: 30px;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
