<template>
  <div class="web-content-page" ref="appRef">
    <!-- header -->
    <webHeader></webHeader>
    <!-- 主体视图层 -->
    <div class="web-page-content">
      <div class="leet-layout-content">
        <keep-alive>
          <router-view class="leet-view" v-if="$route.meta.keepAlive" />
        </keep-alive>
      </div>
      <webFooter></webFooter>
    </div>
  </div>
</template>

<script>
import webFooter from "./webFooter";
import webHeader from "./webHeader";
export default {
  name: "layout-page",
  components: {
    webFooter,
    webHeader,
  },
  data() {
    return {};
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="scss">
.web-content-page {
  width: 100%;
  height: 100%;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // transform-origin: left top;
  box-sizing: border-box;
  position: relative;
  .web-page-content {
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
  }
}
</style>
