<template>
  <div class="leet-basis-page">
    <div class="page-header">
      <img src="https://cdn.lnxall.com/banner5.png" alt="" srcset="" />
      <div class="title-box">
        <div class="title-content animate__animated animate__backInLeft">
          <img
            src="https://cdn.lnxall.com/left-icon.png"
            style="width: 73px; height: 53px; left: 0"
          />
          <span>WooLink OS系统</span>
          <img
            src="https://cdn.lnxall.com/right-icon.png"
            style="width: 68px; height: 60px; right: 0"
          />
        </div>
        <div
          class="sub-content animate__animated animate__backInLeft animate__delay-1s"
        >
          <img
            src="https://cdn.lnxall.com/left-border.png"
            style="width: 200px; height: 3px; left: 0; top: 35px"
          />
          <span>WooLink 智能边缘网关 · 运维管理平台</span>
          <img
            src="https://cdn.lnxall.com/right-border.png"
            style="width: 200px; height: 3px; right: 0; top: 35px"
          />
        </div>
      </div>
    </div>
    <div class="leet-tab-bar">
      <div class="tab-bar-content">
        <div
          class="tab-item"
          :class="[active == 1 ? 'activetab' : '']"
          @click="onTabChange(1)"
        >
          WooLink OS系统
        </div>
        <div
          class="tab-item"
          :class="[active == 2 ? 'activetab' : '']"
          @click="onTabChange(2)"
        >
          WooLink智能边缘网关
        </div>
        <div
          class="tab-item"
          :class="[active == 3 ? 'activetab' : '']"
          @click="onTabChange(3)"
        >
          运维管理平台
        </div>
      </div>
    </div>
    <!-- one  -->
    <div class="page-body" v-if="active == 1">
      <div class="page-body-content">
        <div class="selection-part-one">
          <titleBar title="WooLink OS系统"></titleBar>
          <div class="content">
            <div class="imgbox">
              <img src="https://cdn.lnxall.com/basis01.png" alt="" srcset="" />
            </div>
            <div class="info">
              WooLink
              OS是简捷物联专为适配边缘侧资源受限设备打造的一款轻量级嵌入式物联网操作系统，具备软件定义硬件的功能，支持二次开发，帮助客户在万物互联的趋势中抢占先机。
            </div>
          </div>
        </div>
      </div>
      <div class="page-full-body-content">
        <div class="selection-part-two">
          <div class="section-title">产品优势</div>
          <div class="section-content">
            <div class="item-list" style="margin-right: 30px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs1.png" /></div>
              <div class="titlebox">跨平台</div>
              <div class="info">
                灵活适配主流处理器架构，兼具安全稳定性以及可拓展性
              </div>
            </div>
            <div class="item-list" style="margin-right: 0px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs2.png" /></div>
              <div class="titlebox">二次开发</div>
              <div class="info">
                采用应用层插件化设计，可根据实际需求定义相应应用插件；应用可在线管理，方便功能二次开发以及第三方管理平台接入
              </div>
            </div>
            <div class="item-list" style="margin-right: 30px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs3.png" /></div>
              <div class="titlebox">数据安全</div>
              <div class="info">
                保障远端设备处于高可靠、不间断的网络连接中；可配置采集任务主动采集数据，按照自定义规则上报
              </div>
            </div>
            <div class="item-list" style="margin-right: 0px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs4.png" /></div>
              <div class="titlebox">远程运维</div>
              <div class="info">
                支持利用物联网终端设备原有本地调试手段进行远程调试；支持远程升级
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- two -->
    <div class="page-body" v-if="active == 2">
      <div class="page-body-content">
        <div class="selection-part-one" style="height: 610px">
          <titleBar title="WooLink智能边缘网关"></titleBar>
          <div class="content">
            <div
              class="imgbox"
              style="width: 710px; height: 327px; margin-top: 76px"
            >
              <img
                style="width: 601px; height: 315px"
                src="https://cdn.lnxall.com/basis03.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="info" style="margin-top: 130px; margin-left: 22px">
              智能边缘系列网关将传统网关的功能整合为一体，通过云计算、AI+IoT技术，对采集的数据进行边缘计算，提高设备本地业务联动及计算能力，使工业现场各种设备的管理及运维更高效便捷，为客户赋能。
            </div>
          </div>
        </div>
      </div>
      <div class="page-full-body-content">
        <div class="selection-part-two">
          <div class="section-title">产品优势</div>
          <div class="section-content" @mouseenter="cardactive=false" @mouseleave="cardactive=true">
            <div class="item-card-list" :class="[cardactive ? 'actvie-card':'']">
              <div class="card-title">经济性</div>
              <div class="card-info">
                降低开发维护、通讯、实施等全方位的成本
              </div>
            </div>
            <div class="item-card-list">
              <div class="card-title">安全保障</div>
              <div class="card-info">
                管理平台私有云部署、数据加密传输、权限分离，提供多维度的安全保障
              </div>
            </div>
            <div class="item-card-list">
              <div class="card-title">开放生态</div>
              <div class="card-info">
                基于WooLink OS系统打造，可实现无代码二次开发
              </div>
            </div>
            <div class="item-card-list">
              <div class="card-title">数据传输可靠</div>
              <div class="card-info">
                工业级设计，数据本地存储、断点续传，设备选型灵活多样
              </div>
            </div>
            <div class="item-card-list">
              <div class="card-title">业务场景多样性</div>
              <div class="card-info">
                能耗、环境、电气、照明、安防等各种环境均有落地
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- three -->
    <div class="page-body" v-if="active == 3">
      <div class="page-body-content">
        <div class="selection-part-one" style="height: 650px">
          <titleBar title="运维管理平台"></titleBar>
          <div class="content">
            <div
              class="imgbox"
              style="width: 601px; height: 315px; margin-top: 160px"
            >
              <img
                style="width: 601px; height: 315px"
                src="https://cdn.lnxall.com/basis02.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="info" style="margin-top: 200px; margin-left: 22px">
              平台轻量化——面向物联网领域中的终端设备商、系统集成商、应用服务商、能力提供商等，配合WooLink
              OS提供高效便捷的设备集成、数据分析和应用开发能力。
            </div>
          </div>
        </div>
      </div>
      <div class="page-full-body-content" style="height: 939px">
        <div class="selection-part-two">
          <div class="section-title">产品优势</div>
          <div class="section-content">
            <div class="item-list" style="margin-right: 30px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs5.png" /></div>
              <div class="titlebox">平台灵活部署</div>
              <div class="info">
                平台支持公有云、私有云灵活部署，兼顾安全性和便捷性
              </div>
            </div>
            <div class="item-list" style="margin-right: 0px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs6.png" /></div>
              <div class="titlebox">协议可视化配置</div>
              <div class="info">0编程实现设备协议解析</div>
            </div>
            <div class="item-list" style="margin-right: 30px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs7.png" /></div>
              <div class="titlebox">设备直接接入</div>
              <div class="info">
                设备可通过WooLink网关接入平台或直接接入平台
              </div>
            </div>
            <div class="item-list" style="margin-right: 0px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs8.png" /></div>
              <div class="titlebox">软件定义硬件</div>
              <div class="info">
                通过设备模版及网关应用共享分发，真正实现软件定义硬件
              </div>
            </div>
            <div class="item-list" style="margin-right: 30px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs9.png" /></div>
              <div class="titlebox">数据一键上云</div>
              <div class="info">无缝支持阿里云、华为云等物联网平台</div>
            </div>
            <div class="item-list" style="margin-right: 0px">
              <div class="iconbox"><img src="https://cdn.lnxall.com/bs10.png" /></div>
              <div class="titlebox">业务应用使能</div>
              <div class="info">
                用户自定义语义化物模型，快速搭建业务应用平台
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "@/components/titleBar";
export default {
  name: "basis-page",
  components: {
    titleBar,
  },
  data() {
    return {
      active: 1,
      cardactive: true
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="scss">
.leet-basis-page {
  box-sizing: border-box;
  .page-header {
    height: 860px;
    box-sizing: border-box;
    position: relative;
    background-color: #0b0b0b;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .title-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 346px;
      box-sizing: border-box;
      .title-content {
        width: 628px;
        height: 64px;
        margin: 0 auto;
        position: relative;
        color: #ffffff;
        font-size: 48px;
        letter-spacing: 4.80000019px;
        font-family: PingFangSC-Medium;
        text-align: center;
        box-sizing: border-box;
        img {
          position: absolute;
        }
      }
      .sub-content {
        margin: 0 auto;
        position: relative;
        width: 1298px;
        height: 72px;
        text-align: center;
        color: #ffffff;
        font-size: 40px;
        letter-spacing: 4px;
        font-family: PingFangSC-Medium;
        line-height: 72px;
        margin-top: 60px;
        img {
          position: absolute;
        }
      }
    }
  }
  .leet-tab-bar {
    height: 90px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: inset 0px -1px 1px 0px rgb(200 200 200 / 50%);
    .tab-bar-content {
      width: 1200px;
      max-width: 1200px;
      margin: 0 auto;
      height: 90px;
      line-height: 90px;
      display: flex;
      .tab-item {
        width: 400px;
        text-align: center;
        color: #555555;
        font-size: 24px;
        letter-spacing: 1px;
        font-family: PingFangSC-Medium;
        cursor: pointer;
      }
      .activetab {
        color: #1e4a9d;
        border-bottom: 5px solid #1e4a9d;
      }
    }
  }
  .page-body {
    box-sizing: border-box;
    .page-body-content {
      margin: 0 auto;
      width: 1200px;
      max-width: 1200px;
      box-sizing: border-box;
      .selection-part-one {
        height: 470px;
        margin-top: 76px;
        .content {
          display: flex;
          justify-content: space-between;
          .imgbox {
            height: 201px;
            width: 314px;
            box-sizing: border-box;
            margin-top: 52px;
            img {
              width: 228px;
              height: 201px;
            }
          }
          .info {
            overflow-wrap: break-word;
            color: #222222;
            font-size: 22px;
            letter-spacing: 1px;
            line-height: 48px;
            text-align: left;
            margin-left: 85px;
            margin-top: 70px;
          }
        }
      }
    }
    .page-full-body-content {
      width: 100%;
      height: 726px;
      background-color: rgba(72, 136, 209, 0.06);
      box-sizing: border-box;
      .selection-part-two {
        margin: 0 auto;
        width: 1202px;
        height: 726px;
        max-width: 1202px;
        box-sizing: border-box;
        border: 1px solid transparent;
        .section-title {
          width: 100%;
          text-align: center;
          color: #222222;
          font-size: 30px;
          letter-spacing: 2px;
          font-family: PingFangSC-Medium;
          line-height: 42px;
          box-sizing: border-box;
          margin-top: 100px;
        }
        .section-content {
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          .item-list {
            height: 202px;
            border-radius: 8px;
            background: linear-gradient(360deg, #ffffff 0%, #ffffff 100%);
            border: 1px solid #f5f5f5;
            box-shadow: 0px 8px 20px 0px rgb(213 213 227 / 20%);
            background-size: 620px 240px;
            width: 582px;
            // margin: 0 38px 38px 0;
            margin-bottom: 38px;
            position: relative;
            .iconbox {
              position: absolute;
              top: 39px;
              left: 55px;
              width: 48px;
              height: 48px;
              img {
                width: 48px;
                height: 48px;
              }
            }
            .titlebox {
              position: absolute;
              top: 39px;
              left: 145px;
              width: 200px;
              height: 30px;
              color: #222222;
              font-size: 24px;
              font-family: PingFangSC-Medium;
              line-height: 30px;
              text-align: left;
            }
            .info {
              position: absolute;
              top: 78px;
              left: 145px;
              overflow-wrap: break-word;
              color: #555555;
              font-size: 20px;
              line-height: 36px;
              text-align: left;
              margin-top: 9px;
              width: 400px;
            }
          }
          // three
          .item-card-list {
            width: 240px;
            height: 360px;
            box-sizing: border-box;
            background-image: url('https://cdn.lnxall.com/assets/basis04.gif');
            background-repeat: no-repeat;
            background-size: 240px 360px;
            position: relative;
            box-shadow: 4px 0px 84px 0px rgba(183,188,205,0.17);
            .card-title {
              position: absolute;
              top: 82px;
              left: 30px;
              height: 30px;
              font-size: 24px;
              font-family: PingFangSC-Medium;
              line-height: 30px;
              text-align: left;
            }
            .card-info {
              position: absolute;
              width: 178px;
              top: 110px;
              left: 30px;
              font-size: 20px;
              line-height: 36px;
              text-align: left;
              margin-top: 27px;
            }
          }
          .actvie-card{
            background-image: url('https://cdn.lnxall.com/assets/basis05.gif') !important;
            color: #fff !important;
          }
          .item-card-list:hover{
            background-image: url('https://cdn.lnxall.com/assets/basis05.gif') !important;
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
