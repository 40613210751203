import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/index";
import basisPage from "@/views/basis/index";
import carbonPage from "@/views/carbon/index";
import energyPage from "@/views/energy/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    component: Layout,
    meta: {
      keepAlive: true
    },
    children: [
      {
        path: "/index",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: {
          keepAlive: true
        },
      },
    ],
  },
  {
    path: "/carbon",
    component: Layout,
    meta: {
      keepAlive: true
    },
    children: [
      {
        path: "/carbon",
        component: () => import("@/views/carbon/index"),
        name: "carbon",
        meta: {
          keepAlive: true
        },
      },
    ],
  },
  {
    path: "/energy",
    component: Layout,
    meta: {
      keepAlive: true
    },
    children: [
      {
        path: "/energy",
        component: () => import("@/views/energy/index"),
        name: "energy",
        meta: {
          keepAlive: true
        },
      },
    ],
  },
  {
    path: "/basis",
    component: Layout,
    meta: {
      keepAlive: true
    },
    children: [
      {
        path: "/basis",
        component: () => import("@/views/basis/index"),
        name: "basis",
        meta: {
          keepAlive: true
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
